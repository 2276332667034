import React, {useState, useCallback, useEffect} from 'react';
import Popup from '../components/Popups/Popup';
import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Stage from '../components/Stage';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from "gatsby"
import parse from 'html-react-parser';


import './styles.scss';
import { on } from 'stream';

interface HomeProps {
  pageContext: {
    lang: string,
    heading: string,
    intro: string,
    fullPackage: {
        heading: string,
        text: string,
        buttonLabel?: string
    },
    onlinePackage: {
        heading: string,
        text: string,
        buttonLabel: string
    },
    bottomText: string,
    language: string,
    country: string
  },
  location: string

};


const Home: React.FC<HomeProps> = ({
  pageContext: {
    heading,
    intro,
    fullPackage,
    onlinePackage,
    bottomText,
    language,
    country
  } 
}) => {


  return (
    <>
        <Header language={language} country={country} urlParts={[country]}/>
        <div className={`home-wrapper lang-${language} country-${country}`}>
            <div className="top">
                <div className="intro">
                    <h1>{heading}</h1>
                    {parse(intro)}
                </div>
                <img src={`/home/${country}-overview.png`} alt="" />
            </div>
            <div className="bottom">
                <div className="card">
                    <h2>{fullPackage.heading}</h2>
                    <p>{fullPackage.text}</p>
                    { fullPackage.buttonLabel ? <a onClick={(e) => {e.preventDefault()}} className='button disabled' aria-disabled="true" style={{cursor: "default"}} href='#'>{fullPackage.buttonLabel}</a>  : ""}
                </div>
                <div className="card">
                    <h2>{onlinePackage.heading}</h2>
                    <p>{onlinePackage.text}</p>
                    <a href={`/${country}/${language}/reception`} className="button">{onlinePackage.buttonLabel}</a>
                </div>
            </div>
        </div>
        <div className="footer">
            <p>{parse(bottomText)}</p>
        </div>
    </>
  );
};

export default Home;